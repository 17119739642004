export const apiPrefix = 'https://crmapi.monsterenergy.com/api';

export const APIList = {
  // CRM
  Login: `${apiPrefix}/Auth/login`,
  RefreshToken: `${apiPrefix}/Token/refresh`,
  PromotionEntry: `${apiPrefix}/Promotions`,
  GetSuppressionList: `${apiPrefix}/suppressions`,
  SearchSuppressionList: `${apiPrefix}/suppressions/search`,
  AddNumberToSuppressionList: `${apiPrefix}/suppressions/add`,
};
