import React from 'react';

import './Button.css';

interface Props {
  label: string;
  disabled?: boolean;
  onClick: () => void;
}

const Button: React.FC<Props> = ({ label, disabled, onClick }) => {
  return (
    <div className="ButtonWrapper">
      <button onClick={onClick} className="Button" disabled={disabled}>
        {label}
      </button>
    </div>
  );
};

export default Button;
