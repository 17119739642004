import { useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';
import { isBefore, parse, subYears } from 'date-fns';
import { boolean, object, SchemaOf, string } from 'yup';

import { errorMessages } from '../../common/constants/errorMessages';
import { UserInfoForm } from '../../common/models/user';

const validateOver18 = (d: string, m: string, y?: string): boolean => {
  if (!y) return true;
  const today = new Date();
  const birthDateStr = `${y}-${m || 1}-${d || 1}`;
  const birthDate = parse(birthDateStr, 'y-M-d', today);
  const ageLimit = subYears(today, 18);
  return isBefore(birthDate, ageLimit);
};

const userInfoSchema: SchemaOf<UserInfoForm> = object().shape({
  firstName: string()
    .required(errorMessages.required)
    .max(50, errorMessages.maxLength),
  lastName: string()
    .required(errorMessages.required)
    .max(50, errorMessages.maxLength),
  email: string().required(errorMessages.required).email(errorMessages.email),
  phone: string()
    .required(errorMessages.required)
    .matches(/^\(?\d{3}\)?[-.\s]?\d{3}[-.\s]?\d{4}$/, errorMessages.phone),
  postalCode: string()
    .required(errorMessages.required)
    .matches(
      /^[a-zA-Z][0-9][a-zA-Z] [0-9][a-zA-Z][0-9]$/,
      errorMessages.postalCode,
    ),
  birthYear: string()
    .required(errorMessages.required)
    .test('is-over-18', 'You must be at least 18 years old', function (value) {
      const { birthDay, birthMonth } = this.parent;
      return validateOver18(birthDay, birthMonth, value);
    }),
  birthMonth: string().required(errorMessages.required),
  birthDay: string().required(errorMessages.required),
  tncAccept: boolean().oneOf([true], errorMessages.required).default(false),
  receiveEmail: boolean().default(false),
});

const useUserInfoForm = () => {
  const formInstance = useForm<UserInfoForm>({
    mode: 'all',
    resolver: yupResolver(userInfoSchema),
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      postalCode: '',
      birthYear: '',
      birthMonth: '',
      birthDay: '',
      tncAccept: false,
      receiveEmail: false,
    },
  });

  return formInstance;
};

export default useUserInfoForm;
