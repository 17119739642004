import React from 'react';

import SelectField from '../SelectField';
import './ScoreInput.css';

interface Props {
  label: string;
  name: string;
}

const ScoreInput: React.FC<Props> = ({ label, name }) => {
  return (
    <div className="ScoreInput">
      <p className="label">{label}</p>
      <div className="select-box">
        <SelectField name={name} options={[0, 1, 2, 3, 4]} isNumber />
      </div>
    </div>
  );
};

export default ScoreInput;
