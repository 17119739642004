import { UserInfoForm } from '../models/user';

export namespace Storage {
  const infoKey = 'mgolfUserInfo';

  export function setData(data: UserInfoForm) {
    if (window.localStorage) {
      window.localStorage.setItem(infoKey, JSON.stringify(data));
    }
  }

  export function getData(): UserInfoForm | undefined {
    if (window.localStorage && window.localStorage.getItem(infoKey)) {
      try {
        const strData = window.localStorage.getItem(infoKey);
        return strData ? JSON.parse(strData) : undefined;
      } catch (e) {
        return undefined;
      }
    }
    return undefined;
  }

  export function removeData() {
    if (window.localStorage) {
      window.localStorage.removeItem(infoKey);
    }
  }
}
