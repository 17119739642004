import React from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import './App.css';
import Complete from './pages/Complete';
import ErrorPage from './pages/Error';
import GameScore from './pages/GameScore';
import UserInfo from './pages/UserInfo';

const router = createBrowserRouter([
  { path: '/', element: <UserInfo />, errorElement: <ErrorPage /> },
  { path: '/score', element: <GameScore /> },
  { path: '/congrats', element: <Complete /> },
]);

function App() {
  return (
    <div className="App">
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
