import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import Brand from '../../common/components/Brand';
import { Storage } from '../../common/utils/storage';
import './Complete.css';

const Complete: React.FC = () => {
  const { state } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (!state) {
      navigate('/', { replace: true });
    } else {
      Storage.removeData();
    }
  }, [state, navigate]);

  return (
    <div className="CompleteContainer">
      <Brand />

      <div className="CompleteInfo">
        <h2 className="CompleteTitle">Thanks For Playing!</h2>
        <p className="CompleteSubtitle">Total Shots</p>
        <h2 className="TotalPoints">{state?.scores || 0}</h2>
        <p className="CompleteNote">
          Please see one of our staff <br /> to claim your prize!
        </p>
      </div>
    </div>
  );
};

export default Complete;
