import React, { ReactNode, useRef } from 'react';
import { useFormContext } from 'react-hook-form';

import clsx from 'clsx';

import { getRandomId } from '../../utils';
import './Checkbox.css';

interface Props {
  label?: string;
  LabelEl?: ReactNode;
  name: string;
}

const Checkbox: React.FC<Props> = ({ label, name, LabelEl }) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();
  const idRef = useRef(getRandomId());

  const error = errors[name];

  return (
    <div className="CheckboxWrapper">
      <label htmlFor={idRef.current} className="CheckboxLabel">
        <input
          type="checkbox"
          id={idRef.current}
          className={clsx(['Checkbox', !!error && 'error'])}
          {...register(name)}
        />
        {LabelEl ? LabelEl : label}
      </label>
    </div>
  );
};

export default Checkbox;
