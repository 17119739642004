import { getDaysInMonth } from 'date-fns';

export const getRandomId = (): string => {
  return Math.trunc(Math.random() * new Date().getTime()).toString();
};

export const getYearsOfBirth = () => {
  const currentYear = new Date().getFullYear();
  const startYear = currentYear - 101;
  const endYear = currentYear - 1;

  return Array.from(
    { length: endYear - startYear + 1 },
    (_, i) => startYear + i,
  ).reverse();
};

export const getDaysOfBirth = (year?: number, month = 1) => {
  if (!year) return [];

  const totalDays = getDaysInMonth(new Date(year, month - 1));
  return Array.from({ length: totalDays }, (_, i) => i + 1);
};

export const formatPhoneNumber = (value: string) => {
  const digitsOnly = value.replace(/\D/g, '');
  const areaCode = digitsOnly.substring(0, 3);
  const firstPart = digitsOnly.substring(3, 6);
  const secondPart = digitsOnly.substring(6, 10);

  if (digitsOnly.length > 6) {
    return `${areaCode}-${firstPart}-${secondPart}`;
  } else if (digitsOnly.length > 3) {
    return `${areaCode}-${firstPart}`;
  }

  return digitsOnly;
};

export const formatPostalCode = (value: string) => {
  const firstPart = value.substring(0, 3);
  const secondPart = value.substring(3, 6);

  if (value.length > 3) {
    return `${firstPart} ${secondPart}`;
  }

  return value;
};

export const formatTwoDigits = (numStr?: string): string => {
  return `0${numStr}`.slice(-2);
};

export const generateFirestoreId = () => {
  const chars =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let autoId = '';
  for (let i = 0; i < 20; i++) {
    autoId += chars.charAt(Math.floor(Math.random() * chars.length));
  }
  return autoId;
};
