import React, { useEffect } from 'react';
import { FormProvider } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import Brand from '../../common/components/Brand';
import Button from '../../common/components/Button';
import Checkbox from '../../common/components/Checkbox';
import SelectField from '../../common/components/SelectField';
import TextField from '../../common/components/TextField';
import PhoneField from '../../common/components/TextField/PhoneNumber';
import PostalField from '../../common/components/TextField/PostalCode';
import { monthOptionLabels, monthOptions } from '../../common/constants/date';
import { UserInfoForm } from '../../common/models/user';
import { getDaysOfBirth, getYearsOfBirth } from '../../common/utils';
import { Storage } from '../../common/utils/storage';
import './UserInfo.css';
import useUserInfoForm from './useUserInfoForm';

const UserInfo: React.FC = () => {
  const navigate = useNavigate();
  const formInstance = useUserInfoForm();
  const { handleSubmit, watch, resetField, trigger, formState } = formInstance;

  const { isDirty, isValid, dirtyFields, errors } = formState;

  const birthYear = watch('birthYear');
  const birthYearError = errors['birthYear'];
  const birthMonth = watch('birthMonth');
  const birthMonthError = errors['birthMonth'];
  const birthDay = watch('birthDay');
  const birthDayError = errors['birthDay'];

  const onSubmit = (data: UserInfoForm) => {
    Storage.setData(data);
    navigate('/score');
  };

  useEffect(() => {
    if (!!dirtyFields['birthDay']) {
      trigger('birthYear');
      trigger('birthMonth');
      trigger('birthDay');
    }
  }, [birthDay, dirtyFields, trigger]);

  useEffect(() => {
    if (!!dirtyFields['birthMonth']) {
      resetField('birthDay');
      trigger('birthYear');
      trigger('birthDay');
    }
  }, [birthMonth, dirtyFields, trigger, resetField]);

  useEffect(() => {
    if (!!dirtyFields['birthYear']) {
      resetField('birthDay');
      trigger('birthMonth');
      trigger('birthDay');
    }
  }, [birthYear, dirtyFields, resetField, trigger]);

  const dobMsg =
    birthYearError?.message ||
    birthMonthError?.message ||
    birthDayError?.message ||
    '';

  return (
    <FormProvider {...formInstance}>
      <form action="">
        <div className="UserInfoContainer">
          <Brand />

          <h2 className="UserFormTitle">
            Enter your information below <br /> to start playing
          </h2>

          <div>
            <TextField name="firstName" label="First Name" />
            <TextField name="lastName" label="Last Name" />
            <TextField name="email" label="Email Address" type="email" />
            <PhoneField name="phone" label="Phone Number" />

            <label className="TextFieldLabel">Date of birth</label>
            <div className="DOBWrapper">
              <div className="item">
                <SelectField
                  label="Year"
                  name="birthYear"
                  options={getYearsOfBirth()}
                />
              </div>

              <div className="item">
                <SelectField
                  label="Month"
                  name="birthMonth"
                  options={monthOptions}
                  optionTexts={monthOptionLabels}
                />
              </div>

              <div className="item">
                <SelectField
                  label="Day"
                  name="birthDay"
                  options={getDaysOfBirth(
                    Number(birthYear),
                    Number(birthMonth),
                  )}
                />
              </div>
            </div>

            <p className="dobErrorMessage">{dobMsg}</p>

            <PostalField label="Postal Code" />

            <div>
              <Checkbox
                name="tncAccept"
                LabelEl={
                  <>
                    I accept the{' '}
                    <a
                      href="https://www.monsterenergy.com/en-ca/privacy-policy"
                      title="Monster Energy Privacy Policy"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Privacy Policy
                    </a>
                  </>
                }
              />
            </div>

            <div>
              <Checkbox
                name="receiveEmail"
                label="Please send me promotional emails and texts from Monster Energy. I understand I can unsubscribe at any time."
              />
            </div>

            <Button
              label="Play"
              onClick={handleSubmit(onSubmit)}
              disabled={!isDirty || !isValid}
            />
          </div>
        </div>
      </form>
    </FormProvider>
  );
};

export default UserInfo;
