import React, { useRef } from 'react';
import { useFormContext } from 'react-hook-form';

import clsx from 'clsx';

import { getRandomId } from '../../utils';
import './SelectField.css';

interface Props {
  name: string;
  label?: string;
  options?: number[];
  optionTexts?: string[];
  isNumber?: boolean;
  isError?: boolean;
}

const SelectField: React.FC<Props> = ({
  name,
  label,
  optionTexts,
  options = [],
  isNumber = false,
  isError = false,
}) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();
  const idRef = useRef(getRandomId());

  const error = isError || errors[name];

  return (
    <div className="SelectFieldWrapper">
      <select
        id={idRef.current}
        className={clsx(['SelectField', !!error && 'error'])}
        {...register(name, { valueAsNumber: isNumber })}
      >
        {label !== undefined && <option value="">{label || 'Select'}</option>}
        {options.map((opt, idx) => (
          <option key={opt} value={opt}>
            {(optionTexts && optionTexts[idx]) || opt}
          </option>
        ))}
      </select>
    </div>
  );
};

export default SelectField;
