import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { yupResolver } from '@hookform/resolvers/yup';
import { array, number, object } from 'yup';

import Brand from '../../common/components/Brand';
import Button from '../../common/components/Button';
import ScoreInput from '../../common/components/ScoreInput';
import { ScoresForm } from './models/score';
import { addUser } from '../../common/firebase';
import { APIList } from '../../common/utils/apiList';
import { post } from '../../common/utils/httpUtil';
import { Storage } from '../../common/utils/storage';
import './GameScore.css';
import { formatTwoDigits } from '../../common/utils';

type GameScoreType = Pick<ScoresForm, 'scores'>;

const GameScore: React.FC = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [userInfo] = useState(Storage.getData());
  const [total, setTotal] = useState(0);

  const formInstance = useForm<GameScoreType>({
    mode: 'onTouched',
    resolver: yupResolver(
      object().shape({
        scores: array().of(number().min(1)),
      }),
    ),
    defaultValues: { scores: [0, 0, 0] },
  });

  const {
    watch,
    formState: { isValid },
    handleSubmit,
  } = formInstance;
  const score0 = watch('scores.0');
  const score1 = watch('scores.1');
  const score2 = watch('scores.2');

  const loginCRM = async () => {
    return await post(APIList.Login, {
      Username: process.env.REACT_APP_CRM_USER_NAME,
      Password: process.env.REACT_APP_CRM_PASSWORD,
    });
  };

  const syncPromotion = async () => {
    const promotionEntry = {
      company: 'GOcxm',
      brand: 'Monster',
      promotionName: '2023_05_Monster_CrushTheGreen_BudStage_CA',
      firstName: userInfo?.firstName,
      lastName: userInfo?.lastName,
      email: userInfo?.email,
      phone: userInfo?.phone,
      DOB: `${userInfo?.birthYear}-${formatTwoDigits(
        userInfo?.birthMonth,
      )}-${formatTwoDigits(userInfo?.birthDay)}`,
      storeName: '',
      country: 'CA',
      postalCode: userInfo?.postalCode,
    };

    return await post(APIList.PromotionEntry, promotionEntry);
  };

  const onSubmit = async (data: GameScoreType) => {
    if (!userInfo) return;

    setIsLoading(true);

    try {
      await addUser(userInfo, data.scores);
      console.info('[APP] ADD USER SUCCESSFULLY!');
      const sumarizeScore = data.scores.reduce((acc, curr) => acc + curr, 0);
      const { data: res } = await loginCRM();
      const { token, refreshToken } = res;
      if (window.localStorage) {
        window.localStorage.setItem('token', token);
        window.localStorage.setItem('refreshToken', refreshToken);
      }

      if (token) {
        await syncPromotion();
        console.info('[CRM] SYNCED!');
      }

      navigate('/congrats', {
        replace: true,
        state: { scores: sumarizeScore },
      });
    } catch (err) {
      console.log(err);
      navigate('/', { replace: true });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setTotal(score0 + score1 + score2);
  }, [score0, score1, score2]);

  useEffect(() => {
    if (!userInfo) {
      navigate('/', { replace: true });
    }
  }, [userInfo, navigate]);

  if (!userInfo) {
    return null;
  }

  return (
    <div className="GameScoreScreen">
      <div className="Heading">
        <Brand />

        <div>
          <img src="/golf-banner.png" alt="" />
        </div>

        <div className="info-wrapper user">
          <span className="prop">Scorecard for</span>
          <span className="prop text-bold">{userInfo.firstName}</span>
        </div>

        <div className="info-wrapper scores">
          <span className="prop">Total shots</span>
          <span className="prop">{total}</span>
        </div>
      </div>

      {isLoading ? (
        <div className="lds-ring-wrapper">
          <div className="lds-ring">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      ) : (
        <div className="FormWrapper">
          <div className="score-inputs">
            <FormProvider {...formInstance}>
              {[0, 1, 2].map((holeIndex) => {
                return (
                  <div className="row" key={holeIndex}>
                    <ScoreInput
                      label={`Hole ${holeIndex + 1}`}
                      name={`scores.${holeIndex}`}
                    />
                  </div>
                );
              })}
            </FormProvider>
          </div>

          <div className="row">
            <Button
              label="Finish"
              disabled={!isValid}
              onClick={handleSubmit(onSubmit)}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default GameScore;
