import React from 'react';

import './Brand.css';

const Brand: React.FC = () => {
  return (
    <div className="BrandWrapper">
      <img src="/golf-logo.png" alt="" />
    </div>
  );
};

export default Brand;
