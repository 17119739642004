import React, { useRef } from 'react';
import { useFormContext } from 'react-hook-form';

import clsx from 'clsx';

import { formatPhoneNumber, getRandomId } from '../../utils';
import './TextField.css';

interface Props {
  label: string;
  name: string;
}

const PhoneField: React.FC<Props> = ({ label, name }) => {
  const {
    register,
    setValue,
    formState: { errors },
  } = useFormContext();
  const idRef = useRef(getRandomId());

  const error = errors[name];

  const handlePhoneChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const rawValue = event.target.value.replace(/[^0-9]/g, '');
    const formattedValue = formatPhoneNumber(rawValue);
    setValue('phone', formattedValue, { shouldValidate: true });
  };

  return (
    <div className="TextFieldWrapper">
      <label htmlFor={idRef.current} className="TextFieldLabel">
        {label}
      </label>
      <input
        type="tel"
        className={clsx(['TextFieldInput', !!error && 'error'])}
        {...register(name)}
        onChange={handlePhoneChange}
      />
      {!!error?.message && (
        <p className="errorMessage">{error.message as string}</p>
      )}
    </div>
  );
};

export default PhoneField;
