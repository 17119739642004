import axios from 'axios';

const whiteRoutes = ['Auth/login'];

const commonHeader = {
  contentType: 'application/json',
  accept: 'application/json',
};

interface IAxiosConfig {
  url: string;
  method: string;
  additionalHeaders?: object;
  body?: object;
  params?: object;
}

axios.interceptors.request.use(
  (config) => {
    const url = config.url || '';
    const isWhiteRoute = whiteRoutes.some((route) => url.includes(route));
    if (!isWhiteRoute) {
      const token = localStorage.getItem('token');
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

axios.interceptors.response.use(
  (response) => {
    // TODO: Handle response with status code of errors;
    return response;
  },
  (error) => {
    return Promise.reject(error);
  },
);

const config = (payload: IAxiosConfig) => {
  const { url, method, additionalHeaders, body, params } = payload;
  const headers = {
    ...commonHeader,
    ...additionalHeaders,
  };

  return axios.request({
    url,
    method,
    headers,
    data: body,
    params,
  });
};

export const get = (
  url: string,
  params?: object,
  additionalHeaders?: object,
) => {
  return config({
    url,
    method: 'GET',
    params,
    additionalHeaders,
  });
};

export const post = (
  url: string,
  body?: object,
  additionalHeaders?: object,
) => {
  return config({
    url,
    method: 'POST',
    body,
    additionalHeaders,
  });
};

export const put = (url: string, body?: object, additionalHeaders?: object) => {
  return config({
    url,
    method: 'PUT',
    body,
    additionalHeaders,
  });
};

export const del = (url: string, body?: object, additionalHeaders?: object) => {
  return config({
    url,
    method: 'DELETE',
    body,
    additionalHeaders,
  });
};
