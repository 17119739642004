import React, { useRef } from 'react';
import { useFormContext } from 'react-hook-form';

import clsx from 'clsx';

import { formatPostalCode, getRandomId } from '../../utils';
import './TextField.css';

interface Props {
  label: string;
}

const PostalField: React.FC<Props> = ({ label }) => {
  const {
    register,
    setValue,
    formState: { errors },
  } = useFormContext();

  const idRef = useRef(getRandomId());
  const fieldName = 'postalCode';

  const error = errors[fieldName];

  const handlePhoneChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const rawValue = event.target.value.replace(/[^a-zA-Z0-9]/g, '');
    const formattedValue = formatPostalCode(rawValue);
    setValue(fieldName, formattedValue.toUpperCase(), { shouldValidate: true });
  };

  return (
    <div className="TextFieldWrapper">
      <label htmlFor={idRef.current} className="TextFieldLabel">
        {label}
      </label>
      <input
        type="text"
        className={clsx(['TextFieldInput', !!error && 'error'])}
        {...register(fieldName)}
        onChange={handlePhoneChange}
      />
      {!!error?.message && (
        <p className="errorMessage">{error.message as string}</p>
      )}
    </div>
  );
};

export default PostalField;
