import React, { useRef } from 'react';
import { useFormContext } from 'react-hook-form';

import clsx from 'clsx';

import { getRandomId } from '../../utils';
import './TextField.css';

interface Props {
  label: string;
  name: string;
  type?: 'text' | 'email' | 'tel' | 'number';
  maxLength?: number;
}

const TextField: React.FC<Props> = ({
  label,
  name,
  type = 'text',
  maxLength,
}) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();
  const idRef = useRef(getRandomId());

  const error = errors[name];

  return (
    <div className="TextFieldWrapper">
      <label htmlFor={idRef.current} className="TextFieldLabel">
        {label}
      </label>
      <input
        id={idRef.current}
        type={type}
        className={clsx(['TextFieldInput', !!error && 'error'])}
        maxLength={maxLength}
        {...register(name)}
      />
      {!!error?.message && (
        <p className="errorMessage">{error.message as string}</p>
      )}
    </div>
  );
};

export default TextField;
